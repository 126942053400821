<template>
  <div class="col-12 col-sm-6 text-left mobileForm welcomeText">
    <h1><b>Masuk</b></h1>
    <div style="width: 20vw">
      <form @submit.prevent="signin">
        <div class="form-group mt-3">
          <input
            type=""
            class="form-control"
            id="username"
            :class="classLogin.username"
            aria-describedby="emailHelp"
            placeholder="username"
            required
            v-model="userName"
          />
        </div>
        <div class="form-group mt-3">
          <input
            type="password"
            class="form-control"
            id="password"
            :class="classLogin.password"
            placeholder="password"
            required
            v-model="password"
          />

          <label
            class="text-light float-right mt-2 pointer text-reset-pas"
            @click.prevent="resetPassword"
            >Lupa password?</label
          >
        </div>
        <button
          type="submit"
          class="btn-sign-in btn btn-light mt-3"
          style="width: 20vw"
          :disabled="loading"
        >
          <template v-if="loading">
            <div
              class="spinner-border spinner-border-sm text-primary"
              role="status"
            >
              <span class="sr-only"></span>
            </div>
            <span class="span-loading">Loading</span>
          </template>
          <h5 class="mb-0" v-if="!loading">Login</h5>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  data() {
    return {
      loading: false,
      userName: "",
      password: "",
      accessToken: "",
      refreshToken: "",
      classLogin: {
        username: "",
        password: "",
      },
    };
  },
  watch: {
    userName(val) {
      if (val && this.classLogin.username) {
        this.classLogin.username = "";
      }
    },
    password(val) {
      if (val && this.classLogin.password) {
        this.classLogin.password = "";
      }
    },
  },
  methods: {
    resetPassword() {
      this.$router.push({ name: "ResetPassword" });
    },
    signin() {
      this.loading = true;
      let str = this.userName;
      axios
        .post(`/auth/login-perujuk`, {
          password: this.password,
          username: str.toLowerCase(),
        })
        .then(({ data }) => {
          this.accessToken = data.access_token;
          this.refreshToken = data.refresh_token;
          localStorage.setItem("token", data.access_token);
          this.$store.commit("SET_LIST_TENANTS", data.access_token);

            this.loading = false
            // this.$toast.success('Berhasil Login');
            this.$router.push("/");
            // this.getauth()
            })
            .catch((err) => {
            if (err.response.data.status == 400) {
                if (err.response.data.title == 'PHONE_NOT_VERIFIED') {
                    this.$toast.error('Silahkan melakukan verifikasi');
                    let detail = JSON.parse(err.response.data.detail)
                    const sessionStorageItem = {
                      regisId: detail.id,
                      telepon: detail.telepon
                    }
                    sessionStorage.setItem('verified', JSON.stringify(sessionStorageItem))
                    this.$router.push({name: 'Verifikasi'})
                }else if(err.response.data.errorKey == "badCredential" ){
                    this.classLogin.username =  'is-invalid'
                    this.classLogin.password = 'is-invalid'
                    this.$toast.error('Username/Password yang Anda masukkan salah.');
                } else {
                  this.classLogin.username = "is-invalid";
                  this.classLogin.password = "is-invalid";
                  this.$toast.error("Silahkan Hubungi administrator");
                }
          }
          // this.$toast.error(err.response);
          this.loading = false;
        });
    },
  },
};
</script>
